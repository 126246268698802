<template>
  <el-card>
    <el-form class="global-form2" ref="form" :model="formData" :rules="tableRules" label-width="120px" label-position="right">
      <el-form-item label="预约项目名称" prop="itemName">
        <el-input v-model="formData.itemName" placeholder="请输入名称，如某公园入园预约、某场地预约等"></el-input>
      </el-form-item>
      <el-form-item label="所属公园" prop="parkId">
          <el-select v-model="formData.parkId" placeholder="请选择所属公园" filterable>
            <el-option v-for="(item,index) in projectList" :key="index" :label="item.projectName" :value="item.projectId"></el-option>
          </el-select>
      </el-form-item>

      <div class="table">
        <el-button type="primary" @click="onAdd()">新增时间段</el-button>
        <el-form ref="formTable" :model="formData" :rules="tableRules" label-position="right">
          <el-table ref="addTable" class="global-table" :data="formData.appointItemTimeList">
            <el-table-column label="预约入园时间段" prop="endTime" min-width="200" align='center'>
              <template v-slot = "{ row, $index }">
                <div v-if="$index === addLine" class="time">
                  <el-form-item :prop="'appointItemTimeList.'+ $index +'.startTime'" :rules="tableRules.startTime">
                    <el-time-select
                      placeholder="起始时间"
                      v-model="row.startTime"
                      :picker-options="{
                        start: '08:00',
                        step: '00:30',
                        end: '18:00',
                        minTime: getMinTime(row, $index),
                        maxTime: getMaxTime(row, $index)
                      }">
                    </el-time-select>
                  </el-form-item>
                  &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;
                  <el-form-item :prop="'appointItemTimeList.'+ $index +'.endTime'" :rules="tableRules.endTime">
                    <el-time-select
                      placeholder="结束时间"
                      v-model="row.endTime"
                      :picker-options="{
                        start: '08:00',
                        step: '00:30',
                        end: '18:00',
                        minTime: getEndMinTime(row, $index),
                        maxTime: getMaxTime(row, $index)
                      }">
                    </el-time-select>
                  </el-form-item>
                </div>
                <div v-else>{{row.startTime}} - {{row.endTime}}</div>
            </template>
            </el-table-column>

            <el-table-column label="预约人数上限" prop="limitNum" min-width="200" align='center'>
              <template v-slot = "{ row, $index }">
                <el-form-item v-if="$index === addLine"
                  :prop="'appointItemTimeList.'+ $index +'.limitNum'"
                  :rules="tableRules.limitNum">
                  <el-input v-model="row.limitNum" show-word-limit></el-input>
                </el-form-item>
              <div v-else>{{row.limitNum}}</div>
              </template>
            </el-table-column>

            <el-table-column label="操作" min-width="100" align='center'>
              <template v-slot = "{ row, $index }">
                <el-button type="text" v-if="$index === addLine" @click="onSave(row, $index)">保存</el-button>
                  <template v-else>
                    <el-button type="text" @click="onEdit(row, $index)">编辑</el-button>
                  </template>
                <el-button type="text" @click="onDelete(row, $index)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </div>

      <el-form-item label="开启状态" prop="itemStatus">
        <el-switch v-model="formData.itemStatus"
          active-color="#1AD1B0"
          active-value="1"
          inactive-value="0">
        </el-switch>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit" :disabled="disabled">保存</el-button>
        <el-button @click="$router.back()">取消</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import { pInteger } from '@/utils/validate'
export default {
  name: 'AppointProjectAdd',
  data () {
    return {
      disabled: false,
      addLine: null,
      tableData: [],
      formData: {
        parkId: '',
        itemName: '',
        appointItemTimeList: []
      },
      isEdit: false,
      tableRules: {
        itemName: [
          { required: true, message: '请预约项目名称', trigger: ['blur', 'change'] }
        ],
        parkId: [
          { required: true, message: '请选择所属公园', trigger: ['blur', 'change'] }
        ],
        limitNum: [
          { required: true, message: '请输入预约人数上限', trigger: ['blur', 'change'] },
          { validator: pInteger, trigger: ['blur', 'change'] }
        ],
        startTime: [
          { required: true, message: '请输入预约入园开始时间', trigger: ['blur', 'change'] }
        ],
        endTime: [
          { required: true, message: '请输入预约入园结束时间', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  computed: {
    projectList () {
      return this.$store.state.projectList || []
    }
  },
  created () {
  },
  methods: {
    validNotEdit (index) {
      if (this.addLine !== null) {
        if (this.addLine !== index) {
          this.$message.warning('请先保存数据')
        }
        return false
      }
      return true
    },
    // 时间不重复
    getMinTime (row, index) {
      if (this.formData.appointItemTimeList[index + 1] && this.isEdit) {
        this.getMaxTime(row, index)
      } else {
        const length = this.formData.appointItemTimeList.length
        if (length > 1) {
          const minTime = this.formData.appointItemTimeList[length - 2].endTime
          if (minTime.indexOf('30') !== -1) {
            return Number(minTime.split(':')[0]) + ':00'
          } else {
            return Number(minTime.split(':')[0]) - 1 + ':30'
          }
        }
      }
    },
    getMaxTime (row, index) {
      // 编辑情况下
      if (this.formData.appointItemTimeList[index + 1] && this.isEdit) {
        if (row.endTime) {
          return row.endTime
        } else {
          const maxTime = this.formData.appointItemTimeList[index + 1].startTime
          if (maxTime.indexOf('30') !== -1) {
            return Number(maxTime.split(':')[0]) + 1 + ':00'
          } else {
            return Number(maxTime.split(':')[0]) + ':30'
          }
        }
      } else {
        if (row.endTime) {
          return row.endTime
        }
      }
    },
    getEndMinTime (row, index) {
      if (row.startTime) {
        return row.startTime
      } else {
        const previous = this.formData.appointItemTimeList[index - 1]
        if (previous) {
          if (previous.endTime.indexOf('30') !== -1) {
            return Number(previous.endTime.split(':')[0]) + 1 + ':00'
          } else {
            return Number(previous.endTime.split(':')[0]) + ':30'
          }
        }
      }
    },
    onAdd () {
      if (this.validNotEdit()) {
        this.formData.appointItemTimeList.push({
          limitNum: '',
          startTime: '',
          endTime: ''
        })
        this.addLine = this.formData.appointItemTimeList.length - 1
        this.$nextTick(() => {
          this.$refs.addTable.bodyWrapper.scrollTop = this.$refs.addTable.bodyWrapper.scrollHeight
        })
      }
    },
    onSave (row, index) {
      this.addLine = index
      this.$refs.formTable.validate().then(() => {
        this.addLine = null
        this.isEdit = false
      })
    },
    onEdit (row, index) {
      if (this.validNotEdit(index)) {
        this.isEdit = true
        this.addLine = index
      }
    },
    onDelete (row, index) {
      // 当前可删除
      if (this.addLine === index) {
        this.formData.appointItemTimeList.splice(index, 1)
        this.addLine = null
      } else {
        if (this.validNotEdit()) {
          this.formData.appointItemTimeList.splice(index, 1)
          this.addLine = null
        }
      }
    },
    submit () {
      this.$refs.form.validate().then(() => {
        if (this.validNotEdit()) {
          if (this.formData.appointItemTimeList.length === 0) {
            this.$message.warning('请选择入园时间段！')
            return false
          }
          this.$refs.formTable.validate().then(() => {
            this.disabled = true
            const url = this.$apis.appointManage.insertAppointItem
            this.$axios.post(url, this.formData).then(() => {
              this.$router.push('/appointproject')
            }).finally(() => {
              this.disabled = false
            })
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.table{
  .el-table{
    width: 100%;

    ::v-deep .el-form-item__error {
      width: 100%;
      text-align: center;
    }
  }
  margin: 20px;
  button{
    margin-bottom: 20px;
  }
  .time {
    display: flex;
    justify-content: center;
    ::v-deep .el-input{
      width: 200px;
    }
  }
}
</style>
